import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom" ;
import React from "react";
import Home from "Home";
import Contact from "ContactUs";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path ="/" element = {<Home/>}/> 
        <Route exact path ="/contact" element = {<Contact/>}/>
      </Routes>
    </Router>
  );
};

export default App;

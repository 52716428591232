import "./Home.css";
import {
  Typography,
  AppBar,
  Toolbar,
  Container,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { Link } from "react-router-dom";

import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import lottieP1 from "./lotties/lottie.json";
import p3About from "./aboutus.png";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import SupportIcon from "@mui/icons-material/Support";

const IsSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

const ShowLottie = () => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: lottieP1,
    });
    return () => lottie.stop();
  }, []);
  return <Container ref={anime}></Container>;
};

function CAppBar() {
  return (
    <AppBar
      elevation={1}
      style={{ paddingLeft: "12vh", background: "rgba(0,0,0,0.5)" }}
    >
      <Toolbar>
        <Container maxWidth>
          <Typography
            style={{ fontWeight: 550, opacity: 1 }}
            color="white"
            variant="h4"
          >
            Eugenix
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

const colors = {
  yellow: "#f4d035",
  fadedWhite: "#a39aab",
  darkPurple: "#362d3c",
  white: "#ffffff",
  black: "#000000",
  darkBlue: "#0a2452",
  grey: "#5c6780",
  testGrey: "#636f8a",
};

const styles = {
  container: {
    paddingLeft: "20vh",
    paddingRight: "20vh",
    paddingTop: "10vh",
  },
  p1SuperTitle: {
    color: colors.white,
    fontSize: "3.5vh",
    fontWeight: "lighter",
  },
  p1Title: {
    color: colors.white,
    fontSize: "9vh",
    fontWeight: "bold",
    lineHeight: "1.2",
  },
  p1SubTitle: {
    color: colors.white,
  },
  p2Title: {
    color: colors.white,
    fontSize: "4vh",
    fontWeight: "bold",
  },
  p2Copy: {
    color: colors.testGrey,
    fontSize: "3vh",
  },
  p3Title: {
    color: colors.white,
    fontSize: "4vh",
    fontWeight: "bold",
  },
  p3Copy: {
    color: colors.testGrey,
    fontSize: "3vh",
    textAlign: "center",
  },
  p3Details: {
    color: colors.testGrey,
    fontSize: "2.5vh",
  },
};

const Page1Copy = () => {
  return (
    <Grid container direction="column" justifyItems="center" spacing={"2vh"}>
      <Grid item>
        <Typography style={styles.p1SuperTitle}>Cloud Based</Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            ...styles.p1Title,
            fontSize: IsSmallScreen() ? "7vh" : "8vh",
          }}
        >
          Unified IT Consultancy Platform
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={styles.p1SubTitle}>
          Better way to keep up with the exponential growth of{" "}
          {!IsSmallScreen() ? <br /> : ""}your business with the help of a suite
          of IT Solutions.
        </Typography>
      </Grid>
    </Grid>
  );
};

function Page1() {
  return (
    <Container
      className="Page1"
      maxWidth={true}
      style={{
        ...styles.container,
        paddingLeft: IsSmallScreen() ? "5vh" : "20vh",
        paddingTop: IsSmallScreen() ? "20vh" : "10vh",
        paddingRight: IsSmallScreen() ? "5vh" : "20vh",
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          <Page1Copy />
        </Grid>
        {!IsSmallScreen() && (
          <Grid item xs={6}>
            <ShowLottie />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const Page2Header = () => {
  return (
    <Grid item>
      <Typography style={styles.p2Title}>Why Choose Us ?</Typography>
    </Grid>
  );
};

const Page2Copy = () => {
  return (
    <Grid item>
      <Typography style={styles.p2Copy}>
        Eugenix uses internet technology to provide bespoke software solutions
        to achieve your core business goals. We use the latest interface
        guidelines to provide end users with an intuitive and flexible solution
        that anyone can use with minimal technical skills. We also take pride in
        documenting the software so that users can investigate features
        themselves.
      </Typography>
    </Grid>
  );
};

const P2ImageContent = ({ text, icon }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyItems="center"
      spacing="2vh"
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography style={{ textAlign: "center", fontSize: "2.8vh" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
const Page2Images = () => {
  return (
    <Grid item>
      <Grid container spacing="3vh">
        <Grid item xs={6} md={3}>
          <P2ImageContent
            text=" One Stop Solutions Provider"
            icon={<EmojiObjectsOutlinedIcon className="svg_icons" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <P2ImageContent
            text="Wide Variety of Product and Service"
            icon={<CategoryOutlinedIcon className="svg_icons" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <P2ImageContent
            text="Excellent Track Records"
            icon={<BarChartOutlinedIcon className="svg_icons" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <P2ImageContent
            text="Customer Satisfaction"
            icon={<SupportIcon className="svg_icons" />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

function Page2() {
  return (
    <Container
      className="Page2"
      maxWidth={true}
      style={{
        ...styles.container,
        paddingLeft: IsSmallScreen() ? "5vh" : "20vh",
        paddingTop: IsSmallScreen() ? "15vh" : "10vh",
        paddingRight: IsSmallScreen() ? "5vh" : "20vh",
        height: IsSmallScreen() ? "140vh" : "100vh",
      }}
    >
      <Grid container direction="column" alignItems="center" spacing="9vh">
        <Page2Header />
        <Page2Copy />
        <Page2Images />
      </Grid>
    </Container>
  );
}

const Page3Header = () => {
  return (
    <Grid item>
      <Typography style={styles.p3Title}>About Us</Typography>
    </Grid>
  );
};

const Page3Copy = () => {
  return (
    <Grid item>
      <Typography style={styles.p3Copy}>
        Eugenix is a software consultancy and leading software product
        development company offering world-class web and mobile application
        solutions. The company has a highly talented development team that
        strives for perfection in every job. In addition, is looking for young
        and qualified people around the world and offers quality training. This
        paves the way for a wide range of opportunities for organizations.
      </Typography>
    </Grid>
  );
};

const Page3Details = () => {
  return (
    <Grid item>
      <Grid
        container
        direction={IsSmallScreen() ? "column" : "row"}
        justifyItems="stretch"
        spacing="10vh"
      >
        <Grid item md={6}>
          <Typography style={styles.p3Details}>
            Our Mission : <br />
            <br />
            Changing people's lives through the latest technology and software
            solutions is our number one priority. Plan, analyze, and implement
            to get the results you want.
            <br />
            <br />
            Our Vision : <br />
            <br />
            Build a skilled mind community and work together to bring innovative
            solutions to the IT sector.
          </Typography>
        </Grid>
        <Grid item md={6}>
          <img
            src={p3About}
            alt="Coin"
            width="90%"
            style={{ position: "relative", top: "-5vh", alignSelf: "start" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

function Page3() {
  return (
    <Container
      className="Page3"
      maxWidth={true}
      style={{
        ...styles.container,
        paddingLeft: IsSmallScreen() ? "5vh" : "20vh",
        paddingTop: IsSmallScreen ? "15vh" : "10vh",
        paddingRight: IsSmallScreen() ? "5vh" : "20vh",
        height: IsSmallScreen() ? "210vh" : "100vh",
      }}
    >
      <Grid container direction="column" alignItems="center" spacing="7vh">
        <Page3Header />
        <Page3Copy />
        <Page3Details />
      </Grid>
    </Container>
  );
}

const FDHeader = () => {
  return (
    <Grid item>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography
            style={{ color: colors.white, fontSize: "4vh", fontWeight: "bold" }}
          >
            Eugenix Pte. Ltd.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: colors.testGrey }}>
            3 FRASER STREET #04-23A DUO TOWER SINGAPORE
          </Typography>
        </Grid>
        <Grid item>
          <Link
            to="/contact"
            style={{ textDecoration: "none" }}
            color={colors.testGrey}
            className="custom-link"
          >
            <Typography>Contact Us</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FooterDetail = () => {
  return (
    <Container className="FooterDetails" maxWidth={true}>
      <Grid container alignItems="center" direction="column">
        <FDHeader />
      </Grid>
    </Container>
  );
};

export const CFooter = () => {
  return (
    <footer className="Footer">
      <Container style={{ padding: "2vh" }}>
        <Typography textAlign="center" color={colors.testGrey}>
          © {new Date().getFullYear()} Eugenix Pte. Ltd. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

const Home = () => {
  return (
    <div className="App">
      <CAppBar />
      <Page1 />
      <Page2 />
      <Page3 />
      <FooterDetail />
      <Divider style={{ background: "black" }} />
      <CFooter />
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import "./ContactUs.css";

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const colors = {
  yellow: "#f4d035",
  fadedWhite: "#a39aab",
  darkPurple: "#362d3c",
  white: "#ffffff",
  black: "#000000",
  darkBlue: "#0a2452",
  grey: "#5c6780",
  testGrey: "#636f8a",
};

const IsSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);

  const SubmitForm = () => {
    setSubmitted(true);
  };

  const ThanksCopy = () => {
    return (
      <Grid
        container
        style={{
          height: "60vh",
        }}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography style={{ fontSize: "5vh", textAlign: "center" }}>
            Thanks for contacting us. Our team will get back to you within 24
            hours.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const ContactUsForm = () => {
    return (
      <Grid>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Fill up the form and our team will get back to you within 24
              hours.
            </Typography>
            <form>
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    placeholder="Enter first name"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    placeholder="Enter last name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    placeholder="Enter email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    placeholder="Enter phone number"
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Type your message here"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={SubmitForm}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <div>
      <div
        className="contact-page1"
        style={{
          height: IsSmallScreen() ? "70vh" : "",
          paddingTop: IsSmallScreen() ? "10vh" : "",
        }}
      >
        {!submitted ? <ContactUsForm /> : <ThanksCopy />}
      </div>
      <footer className="Footer">
        <Container style={{ padding: "2vh" }}>
          <Typography textAlign="center" color={colors.testGrey}>
            © {new Date().getFullYear()} Eugenix Pte. Ltd. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default Contact;
